@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  body {
    @apply text-gray-800 bg-white;
  }

  .section {
    @apply px-3 md:px-10
  }

  .centexTextColour {
    @apply text-[#0C416A]
  }

  .centexBackgroundColour {
    @apply bg-[#0C416A]
  }

  .footerLink {
    @apply hover:centexTextColour hover:font-bold
  }

  .blueHoverBtn{
    @apply border border-blue-950 centexTextColour p-1 rounded hover:scale-105 transition-all shadow-inner hover:shadow bg-slate-100 hover:bg-sky-800 hover:text-white
  }
}